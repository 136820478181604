


<template>
  <div v-show="true" class="center" style="padding-top: 45px;padding-bottom: 210px">
    <div style="margin-bottom: 20px;height: auto;border: 1px solid rgba(220, 220, 220, .5);padding: 30px 30px;overflow: hidden">
      <div style="height: 260px;width: 300px;float: left;background-color: blueviolet;margin-right: 65px">
        <img src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png" alt="" style="height: 100%;width: 100%">
      </div>
      <div style="float: left;width: 730px">
        <div style="font-size: 24px;color: black;margin-bottom: 20px"><span>{{detaiItem.title}}</span></div>
        <div style="font-size: 14px;margin-bottom: 30px" class="san_hang_sheng"><p>{{detaiItem.keywords}}</p></div>
        <div style="width: 730px;height: 40px;line-height: 40px;background-color: rgb(248, 248, 248);margin-bottom: 30px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
          <span style="padding-left: 10px">归属高校：{{detaiItem.colleges}}</span>&nbsp;&nbsp;<span :title="detaiItem.expertsCompleting" style="padding-left: 20px">发明人：{{detaiItem.expertsCompleting}}</span>
        </div>
        <div @click="openZoosUrl" style="width: 120px;height: 40px;color: white;line-height: 40px;text-align: center;background-color: #fc7c40">
          咨询成果
        </div>
      </div>
    </div>
    <div style="padding-top: 30px;padding-bottom: 40px;border:1px solid rgba(220, 220, 220, .5);padding-left: 40px;padding-right: 80px;height: auto">
      <div style="font-size: 16px;margin-bottom: 45px"><span>综合介绍</span></div>
      <div style="width: 1080px">
        <div style="line-height: 25px" v-html="detaiItem.abstractDesc">

        </div>
      </div>
    </div>
    <div style="margin-top:20px;height: 210px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
      <div style="font-size: 16px;margin-bottom:30px"><span>专利情况</span></div>
      <div style="width: 1080px;height: 65px;background-color: rgb(248, 248, 248);line-height: 65px;padding-left: 20px ">
        <span style="font-size: 14px">无</span>
      </div>
    </div>

    <div style="margin-top:20px;height: 210px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
      <div style="font-size: 16px;margin-bottom:30px"><span>创新要点</span></div>
      <div style="width: 1080px;padding-left: 20px ">
        <p style="line-height: 25px">
          无
        </p>
      </div>
    </div>
    <div style="margin-top:20px;height: 210px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
      <div style="font-size: 16px;margin-bottom:30px"><span>技术指标</span></div>
      <div style="width: 1080px;height: 65px;background-color: rgb(248, 248, 248);line-height: 65px;padding-left: 20px ">
        <span style="font-size: 14px">无</span>
      </div>
    </div>

    <div style="margin-top:20px;height: 210px;border: 1px solid rgba(220, 220, 220, .5);padding-top: 30px;padding-bottom: 40px;padding-left: 40px;">
      <div style="font-size: 16px;margin-bottom:30px"><span>应用方向</span></div>
      <div style="width: 1080px;line-height: 65px;">
        <p style="line-height: 25px">
          {{detaiItem.category}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {getResultDetail} from "../../plugins/api/resourceApi";
import {errorToast} from "../../plugins/tools/util";

export default {
  name: "PatentSearchDetail",
  data(){
    return {
      detaiItem:{
        title:'',
        abstractDesc:'',
        keywords:'',
        colleges:'',
        expertsCompleting:'',
        category:''
      },
    }
  },
  methods:{
    openZoosUrl(){
      openZoosUrl('chatwin');
    },
  },
  mounted() {
    let id = this.$route.query.id
    getResultDetail({"id":id}).then((res) => {

      if(res.code == 0){
        this.detaiItem = res.result;
      }else{
        errorToast(res.message)
      }
    })

  }
}
</script>

<style scoped lang="scss">
.san_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
/deep/ .ivu-table td{
  height: 80px!important;
}
/deep/ .ivu-table th{
  height: 60px!important;
}
.more {
  display: inline-block;
  float: right;
  width: auto!important;
  cursor: pointer;
}
/deep/ .ivu-page-item-active {
  background-color:#36c2cf ;
  color: white!important;
  border: none;
}
/deep/ .ivu-page-item-active a {
  color: white!important;
}
/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
  border: #36c2cf;
  background-color:#36c2cf ;
}
/deep/ .ivu-checkbox-group {
  display: inline-block;
}
.cgfl {
  position: relative;
  height: 50px;
  border-bottom: 1px solid rgba(220, 220, 220, .5);
  padding: 15px 30px;
  & span {
    display: inline-block;
    width: 100px;
  }

}

/deep/ .ivu-select-selection {
  border-radius: 0;
}
.center {
  width: 1200px;
  margin: 0 auto;
}
</style>
